import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { ToastOptions } from "ngx-toasta";
import { AuthenticationHelper } from "src/app/Services/helpers/authentication-helper.service";
import { PlanoService } from "src/app/Services/plano.service";
import { BidMessageDialogComponent } from "../Widget/PopUp/bid-message-dialog/bid-message-dialog.component";
import { PagamentoService } from "src/app/Services/pagamento.service";
import { FormGroup } from "@angular/forms";
import { PremiumActiveDialogComponent } from "src/app/AdminPanel/Widget/PopUp/PremiumActiveDialog/PremiumActiveDialog.component";
import { Subject } from "rxjs";
enum TabsEnum {
  Pix = 1,
  Cartao = 2,
}
@Component({
  selector: "app-premium-services",
  templateUrl: "./premium-services.component.html",
  styleUrls: ["./premium-services.component.scss"],
})
export class PremiumServicesComponent implements OnInit {
  toastOptions: ToastOptions = {
    title: "",
    msg: "",
    showClose: true,
    timeout: 5000,
    theme: "material",
  };

  plano: any;
  images: string[];

  colunaServicos = 30;
  colunaGratis = 20;
  colunaPremium = 50;

  exibirPagamentoBoleto = false;
  tabsEnum = TabsEnum;
  selectedPaymentTab: TabsEnum = TabsEnum.Cartao;
  valorCobranca: number;
  disabledBtn: boolean = true;
  submitCard: Subject<boolean> = new Subject();

  constructor(
    private planoService: PlanoService,
    private router: Router,
    private authenticationHelper: AuthenticationHelper,
    private dialog: MatDialog,
    private pagamentoService: PagamentoService
  ) {}

  ngOnInit() {
    this.images = ["assets/images/business/premium-services/header.jpg"];
    this.selectedPaymentTab = TabsEnum.Cartao;
  }

  obterPlanoPago() {
    if (!this.authenticationHelper.estaLogado()) {
      this.router.navigate(["/session/signup/2"], {
        queryParams: {
          redirect: "/premium/pagamento/cartao",
        },
      });
      return;
    }

    this.router.navigate(["/premium/pagamento/cartao"]);

    /*this.planoService.obterPlanoPago()
      .subscribe(resposta => {
        this.plano = resposta.dados;
        this.router.navigate(['/premium/pagamento/' + this.plano.id]);
      });*/
  }

  redirecionarParaPagamentoCartao() {
    this.obterPlanoPago();
    // this.exibirMensagemManutencao();
  }

  redirecionarParaPagamentoPix() {
    if (!this.authenticationHelper.estaLogado()) {
      this.router.navigate(["/session/signup/2"], {
        queryParams: {
          redirect: "/premium/pagamento-pix",
        },
      });
      return;
    }

    this.router.navigate(["/premium/pagamento-pix"]);
    // this.exibirMensagemManutencao();
  }

  redirecionarParaLogin() {
    this.router.navigate(["/session"], {
      queryParams: {
        redirect: "/premium",
      },
    });
  }

  exibirMensagemManutencao() {
    let manutencaoPopup: MatDialogRef<BidMessageDialogComponent>;
    this.dialog.open(BidMessageDialogComponent, {
      data: {
        titulo: "Atenção",
        mensagem:
          "Pagamentos via boleto e cartão estão temporáriamente indisponíveis.<br>Estamos trabalhando para resolver o problema.<br><br>Desculpe pelo incômodo.",
      },
    });
  }

  submeterPagamento() {
    this.submitCard.next(true);
  }
}
