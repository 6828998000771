import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-bid-button",
  templateUrl: "./bid-button.component.html",
  styleUrls: ["./bid-button.component.scss"],
})
export class BidButtonComponent implements OnInit {
  @Input()
  size: "medium" | "small" | "little" = "medium";
  @Input()
  type: "basic" | "flat" | "bordered" | "bordered-secondary";
  @Input()
  buttonText: "";
  @Input()
  color: "primary" | "secondary" = "primary";
  @Input()
  width: string = "auto";
  @Input()
  disabled = false;
  @Input()
  iconClass: string | null = null;
  @Input()
  iconColor: string | null = null;
  
  constructor() {}

  ngOnInit() {}
}
