import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { SignInModel } from "../../../Pages/Session/SignIn/models/signin.model";
import { UserService } from "../../../Services/user.service";
import { ServicoEmAndamentoModel } from "../../Models/Servicos/servico-em-andamento.model";
import { TipoUsuario } from "../../../Pages/Session/customer-type/models/new-user.model";
import { CategoriaServicoEnum } from "../../Models/Servicos/Enums/categoria-servico.enum";
import { PropostaModel } from "../../Models/Anuncios/proposta.model";

@Component({
  selector: "app-in-progress-bids",
  templateUrl: "./in-progress-bids.component.html",
  styleUrls: ["./in-progress-bids.component.scss"],
})
export class InProgressBidsComponent implements OnInit {
  @HostListener("window:resize")
  public onResize(): void {
    this.verifyScreenSize();
  }

  @Input()
  service: ServicoEmAndamentoModel;

  @Input()
  isVertical = false;

  @Input()
  width = "252px";

  @Input()
  showProposals = true;

  @Input()
  showDates = true;

  @Input()
  profileClass = false;

  @Input()
  profileFornecedorClass = false;

  @Output()
  clickEvent = new EventEmitter();
  imagens: Array<string> = [];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
  };
  mobileView: boolean;
  showCountDown = true;
  user: SignInModel;
  tipoUsuario = TipoUsuario;
  categoriaServicoEnum = CategoriaServicoEnum;
  propostaFornecedor: PropostaModel;
  iconPathPosicao = "assets/icons/posicao.svg";
  constructor(private userService: UserService) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.preparaImagensParaCarrousel();
    this.verifyScreenSize();
    // this.showCountDown = new Date(this.service.dataEvento).getTime() > new Date().getTime();
    this.ObtemPropostaFornecedor();
  }

  verifyScreenSize() {
    if (window.innerWidth <= 768) this.mobileView = true;
    else this.mobileView = false;
  }

  private preparaImagensParaCarrousel() {

    if (this.service.imagens) {
      this.imagens = this.service.imagens.map((imagem) => imagem.url);
    } else {
      const imagensPossiveisKeys = [
        "primeiraImagem",
        "segundaImagem",
        "terceiraImagem",
        "quartaImagem"
      ];

      imagensPossiveisKeys.forEach((key) => {
        if (this.service[key]) {
          this.imagens.push(this.service[key]);
        }
      });
    }
  }

  private ObtemPropostaFornecedor() {

    if (this.user.tipoUsuario === this.tipoUsuario.Fornecedor) {
      this.propostaFornecedor = this.service.propostas.find(
        (prop) => prop.fornecedorId === this.user.id
      );

      if (this.propostaFornecedor === undefined) {
        this.propostaFornecedor = this.service.propostasSelecionadas.find(
          (prop) => prop.fornecedorId === this.user.id
        );
      }
    }
  }  

}
